<template>
  <div class="home sWaiting">
    <div v-if="passIncluded">
      <div class="float-left row">
        <img src="./../assets/care_dokter_logo.png" alt="" class="mr-3 tel-log" style="height: 65px;">
        <h3 class="mt-3 telTitle">TELEMEDICINE</h3>
      </div>
      <div class="mb-5 float-right text-right mr-5" v-if="keterangan.reservasi" id="ketTop">
        <h4>{{keterangan.reservasi.jadwal.dokter.tujuanRujukan.nama}}</h4>
        <h4>{{keterangan.reservasi.jadwal.hari}} {{keterangan.reservasi.tanggalKunjungan}}</h4>
        <h4>{{keterangan.reservasi.jamMulai}} - {{keterangan.reservasi.jamSelesai}}</h4>
      </div>

    <!-- v-if="passIncluded && waiting" -->
      <div class="container mt-2" >
        <br><br>
        <h2 class="d-flex justify-content-center mt-3"  v-if="keterangan.reservasi">{{ $store.state.roleDokter ?  keterangan.reservasi.jadwal.dokter.nama : keterangan.reservasi.patient.patientName}}</h2>
        <br>
        <div v-if="!loading">
          <div v-if="timeLeft.length > 1 && !userReady && !askReady">
            <h2 class="font-weight-bold text">Tersedia Dalam</h2>
            <br>
            <flip-countdown :deadline="timeLeft"></flip-countdown>
            <span class="ketIndo">Hari</span>
            <span class="ketIndo">Jam</span>
            <span class="ketIndo">Menit</span>
            <span class="ketIndo">Detik</span>
          </div>
        </div>

        <!-- masuk telmed -->
        <!-- ini hrsnya gaperlu lg -->
        <div v-if="userReady">
          <h2 class="font-weight-bold text-primary">Sudah Tersedia</h2>
          <br>
          <button type="button" class="btn btn-primary btn-msk" @click="$router.push('/telmed')" 
          >Klik Untuk Masuk</button>
        </div>
        <br>

        <!-- ask if ready -->
        <div v-if="loading" class="mb-4">
          <h2>Sedang diarahkan ke sesi telekonsul</h2><br>
          <button class="btn btn-primary" type="button" style="font-size: 25px;" disabled>
          <span class="spinner-border" style="width: 2rem; height: 2rem;" aria-hidden="true"></span>
            Loading...
          </button>
        </div>
        <div v-if="askReady && !loading">
          <h2 class="font-weight-bold text-primary">apakah anda yakin?</h2>
          <br>
          <button type="button" class="btn btn-primary btn-msk" @click="initSocket()" 
          >Masuk Sekarang</button>
        </div>


        <div class="mt-4" v-if="keterangan.reservasi" id="ketBottom">
          <h4>{{keterangan.reservasi.jadwal.dokter.tujuanRujukan.nama}}</h4>
          <h4>{{keterangan.reservasi.jadwal.hari}} {{keterangan.reservasi.tanggalKunjungan}}</h4>
          <h4>{{keterangan.reservasi.jamMulai}} - {{keterangan.reservasi.jamSelesai}}</h4>
        </div>
        <br>  
        <br>
      </div>
    </div>

    <!-- <div class="container" v-if=" !waiting && timeLeft < 0">
      <h3>Sesi Telekonsul anda sudah lewat, silahkan hubungi customer service untuk info lebih lanjut</h3>
      <br><br>
      <br />
      <img src="./../assets/exp97.png" alt="" class="w-100">
      <br><br>  
    </div> -->

    <div class="container" v-if="!passIncluded">
      <div class="card text-center" >
        <div class="card-header">
          Please Input Password
        </div>

        <div class="card-body">
          <form>
            <div class="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" v-model="inputPassword" required>
            </div>
            <button type="submit" class="btn btn-primary" @click.prevent="verifyPass">Submit</button>
          </form>
        </div>

        <div class="card-footer text-muted">
        </div>
      </div>
    </div>
      <a class="footer-tnc" href="/terms-and-conditions"
        style="
        position: absolute;
        bottom: 15px;
        right: 50px;">
      Syarat dan Ketentuan</a>
    </div>
</template>

<script>
import axios from "axios";
import ModalPassword from '../components/ModalPassword';
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import FlipCountdown from 'vue2-flip-countdown';
import { JSEncrypt } from 'jsencrypt';
export default {
  name: "WaitingRoom",
  data(){
    return {
      isPass: false,
      params:{},
      keterangan:{},
      roleDokter: false,
      stompClient:null,
      userReady: false,
      inputPassword:null,
      waiting: false,
      passIncluded: false,
      inputPassword: null,
      early: false,
      timeLeft: '',
      sesiExpired: false,
      readyToRoom: false,
      askReady: false,
      loading: false,
      timeout: 10000
    }
  },
  components: {
    ModalPassword,
    FlipCountdown
  },
  computed:{
    shouldDisplay(s){
      console.log(s);
    }
  },
  methods:{
    parseTime(time) {
      let c = time.split(':');
      // console.log(parseInt(c[0]) * 60 + rpaseInt(c[1]));
       return parseInt(c[0]) * 60 + parseInt(c[1]);
    },
    verifyPass(){
      // rsa key here 
        // this.params.password = inputPassword;
        // New JSEncrypt object
      let encryptor = new JSEncrypt();
      // Setting public key
      encryptor.setPublicKey('MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAK+i+DCC0/gHMonvl0SfsGwHJnsXJvDIY3xeKdR1VHTxr4lFGt/eMq6sQ40cSdyObW/c3fjnzkZIT/eo8Xy0+jMCAwEAAQ==');
      // Encrypted data
      this.params.password = encryptor.encrypt(this.inputPassword);
     
      if (this.inputPassword) {
        this.passIncluded = true
        this.getToken()
      }

    },
    async failedSession(){
      this.$vToastify.error('refreshing token');
      axios.post(`${process.env.VUE_APP_BASE_URL}/telemedicine/api/complete-task/failed-create-opentok-session?assigneeId=${encodeURIComponent(this.params.linkId)}`,{
        headers: {
         "X-TenantId": this.params.tenantId
        }
      })
      .then(data =>{
        this.$router.go(this.$router.currentRoute)
      })
      .catch(err =>{
        this.$vToastify.error(err.response.data.title, err.response.data.errorKey); 
      })
    },
    async getToken(){
      this.timeLeft = '2021-1-1 15:07:00'
      let samepar = window.location.pathname.substring(1)
      
      let str = Object.entries(this.params).map(([key, val]) => key+'='+encodeURIComponent(val)+'').join('&');

      let tenant = JSON.parse(this.params.tenantId)

      axios
      .get(`${process.env.VUE_APP_BASE_URL}/api/teleconsult-room?${str}`,
      {
        headers: {
         "X-TenantId": this.params.tenantId
        }
      })
      .then(({ data }) => {
        // data.accessToken = "T1==cGFydG5lcl9pZD00NzI3NTQ2NCZzaWc9YjQ0NjY1NjI1Yjc4MTVjMjEzOGU0MTI3M2UwNGM4YThlNDA0MWUzNjpzZXNzaW9uX2lkPTFfTVg0ME56STNOVFEyTkg1LU1UWXlOVGd5TmpjeE5qTTJPWDU1TXpSNGVYVk9hSGhTWkdaQmIzRXJjbVZVUmlzMFpsUi1mZyZjcmVhdGVfdGltZT0xNjI1ODI2ODA0Jm5vbmNlPTAuMzI3Mzk4MDA1ODc1ODY4MSZyb2xlPXB1Ymxpc2hlciZleHBpcmVfdGltZT0xNjI4NDE4ODAzJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9"
        // data.sessionId = "1_MX40NzI3NTQ2NH5-MTYyNTgyNjcxNjM2OX55MzR4eXVOaHhSZGZBb3ErcmVURis0ZlR-fg"
        this.keterangan = data
        // console.log(data)

        this.$store.commit('SET_TELE_DATA', data)
        let now = new Date()
        let jamReservasi = new Date(`${this.keterangan.reservasi.tanggalKunjungan} ${this.keterangan.reservasi.jamMulai}:00`)

        // handle disini utk session null
        if (!data.sessionId && data.doctorReadyAt && data.patientReadyAt && data.doctorLeaveAt && data.patientLeaveAt) {
          // telekonsul selesai 
          this.$router.push('/endCall')
        }else if (!data.sessionId){
            if (data.doctorReadyAt && data.patientReadyAt ) {
              this.initSocket()
            }
          // check jam besar/kecil 
          // if (now > jamReservasi) {
            // this.subsSocket()
            // console.log('ini ke ready waiting');
          // }else{
            this.askReady = true
          // }
        }else{
          // sessionId = true
          // this.subsSocket()
            this.askReady = false
            this.loading = true
            let telfo = JSON.parse(localStorage.getItem('telmedinfo'))
            if (!this.$store.state.accessToken) {
              this.$store.commit('SET_TELE_DATA', telfo)
            }
            // console.log('siap mask telmed');
          setTimeout(() => {
            this.$router.push('/telmed')
          }, 3000);
        }

      })
      .catch(err =>{
        this.$vToastify.error(err.response.data.title, err.response.data.errorKey); 
      })
      .finally(()=>{
        
        this.timeLeft = `${this.keterangan.reservasi.tanggalKunjungan} ${this.keterangan.reservasi.jamMulai}:00`
      })
    },
    readyWaiting(){
      this.subsSocket()
  
      let body = JSON.stringify({
        linkId: this.params.linkId,
        accessTok: this.keterangan.accessToken
      })
      this.stompClient.send(`/topic/teleconsult/room/${encodeURIComponent(this.params.linkId)}/${encodeURIComponent(this.params.password)}`, body);
      
    },
    initSocket(){
      console.log('open connection socket');
      this.loading = true
      let str = Object.entries(this.params).map(([key, val]) => key+'='+encodeURIComponent(val)+'').join('&');
      let url = `${process.env.VUE_APP_BASE_URL}/websocket/teleconsult/room?${str}`
  
      // let base = process.env.VUE_APP_MIX_WEB_SOCKET;
      var socket = new SockJS(url);
      this.stompClient = Stomp.over(socket)

      this.stompClient.debug = () => {};
      this.stompClient.connect(
        {},
          frame => {
            
          },
          error => {
              // this.disconnect()
              // setTimeout(() => {
              //   this.readyWaiting()
              //     this.initSocket();
              //     this.$vToastify.info("Reconnecting WebStomp", "Reconnecting"); 
              // }, 1000)
          console.log('Reconnect WebStomp',error);
          }
      );
      setTimeout(() => {
      this.subsSocket()
      }, 2000);
    },
    subsSocket(){
      // ketika gadapet2 socket
      setTimeout(() => {
          console.log('reloading');
          this.failedSession()
      }, this.timeout);

      this.stompClient.subscribe(`/topic/teleconsult/room/${encodeURIComponent(this.params.linkId)}`, tick => {
        let msg = JSON.parse(tick.body)
        if (tick) {
          console.log(msg, 'dapet')
          // location.reload();
          this.getToken()
        }
      }, Headers = {
        password: `${encodeURIComponent(this.params.password)}`
      });
      // }
    },
    disconnect() {
     if (this.stompClient.status === 'CONNECTED') {
        this.stompClient.disconnect();
        console.log('disconnected');
      }
    },
  },
  mounted() {
    var ua = navigator.userAgent;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var x = document.getElementsByTagName('BODY')[0]
    if (isSafari){
      x.classList.add('b-mobile');
      if(/iPhone|iPod/i.test(ua)){
        x.classList.add('iphone')
      }
    }else if(/Android|webOS|Tablet|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(ua)){
      x.classList.add('b-mobile');
    }else  (/Chrome/i.test(ua))

    // let samepar = window.location.pathname.substring(1)
    localStorage.setItem('linkParams', this.$route.fullPath)

    var obj = {}; 
    // this.$route.params.id.replace(/([^=&]+)=([^&]*)/g, function(m, key, value) {
    //   obj[decodeURIComponent(key)] = decodeURIComponent(value);
    // }); 
    this.params = this.$route.query
    // localStorage.setItem('inviteLink', JSON.stringify({
    //   linkId: obj.linkId,
    //   password: obj.password
    // }))

    if (this.params.password) {
      // $("#exampleModal").modal('show');
      this.passIncluded = true
      this.getToken()
      setTimeout(() => {
        // this.initSocket()
      }, 1500);
    }else{
      this.$vToastify.info("please input the right one", "Password needed"); 
    }
    if (this.params.key) {
      this.roleDokter = true
      this.$store.commit('SET_ROLE_DOKTER', true)
    }


  }
};
</script>
<style >
.sWaiting{
  background-image: url(./../assets/Group_124.svg);
  background-size: cover;
  background-position: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  margin-top: 100px;
}

.btn-msk{
  padding: 1.375rem 1.75rem;
      font-size: 2rem;
      line-height: 1.5;
      border-radius: 3.25rem;
}

.tel-log{
  margin-left: 3rem!important;
}

.ketIndo{
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

#ketBottom{
  display: none;
}

.b-mobile .btn-msk{
  padding: 1rem 1.75rem;
  font-size: 1.2rem;
}

.b-mobile #ketBottom{
  display: block;
}

.b-mobile #ketTop{
  display: none;
}

.b-mobile .sWaiting{
  margin-top: 50px;
}

.b-mobile h4{
  font-size: 1rem !important;
}

.b-mobile h2{
  font-size: 1.5rem !important;
}

.b-mobile .tel-log{
  height: 30px !important;
  margin-left: 2rem!important;
  margin-right: .5rem!important;
}

.b-mobile .telTitle{
  font-size: 16px !important;
  margin-top: .5rem!important;
}

.b-mobile .ketIndo{
  margin-left: 1rem;
  margin-right: 1rem;
}

.flip-card__top[data-v-78efe7f6], .flip-card__bottom[data-v-78efe7f6], .flip-card__back-bottom[data-v-78efe7f6], .flip-card__back[data-v-78efe7f6]::before, .flip-card__back[data-v-78efe7f6]::after{
   color: #0062cc !important;
  background: #e9ecef !important;
  border-top: solid 1px #ced4da !important;
}

.flip-clock__slot{
  display: none !important;
}
</style>
